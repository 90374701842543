import React from 'react'
import { Box, Divider, Typography, Button } from '@material-ui/core'
import { handleSelectAll, handleSelectAllSearchResults } from './utils'

export const SelectAllBlock = ({
  checkedIds,
  setCheckedIds,
  dataArray,
  searchResults,
}) => {
  const renderButtonText = () => {
    if (searchResults.length > 0) {
      return searchResults.every(result => checkedIds.includes(result.id))
        ? 'Clear all filtered items'
        : 'Select all filtered items '
    } else {
      return checkedIds.length === dataArray.length ? 'Clear' : 'Select All'
    }
  }

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '10px',
          alignItems: 'center',
        }}
      >
        {searchResults.length > 0 ? (
          <Typography variant="h6" style={{ color: 'gray' }}>
            Selected{' '}
            {
              searchResults.filter(result => checkedIds.includes(result.id))
                .length
            }{' '}
            of {searchResults.length}
          </Typography>
        ) : (
          <Typography variant="h6" style={{ color: 'gray' }}>
            Selected {checkedIds.length} of {dataArray.length}
          </Typography>
        )}

        <Button
          width="max-content"
          style={{
            borderRadius: '20px',
            minWidth: '100px',
            background: '#e8e9f1',
          }}
          onClick={() => {
            if (searchResults.length > 0)
              handleSelectAllSearchResults(setCheckedIds, searchResults)
            else handleSelectAll(dataArray, setCheckedIds)
          }}
        >
          {renderButtonText()}
        </Button>
      </Box>
      <Divider />
    </Box>
  )
}
