import { Box, InputBase, Typography, Button } from '@material-ui/core'
import React, { useState } from 'react'
import { useStyles } from './style'
import useTag from './useTag'
import { tagNameValidation } from './utils'

export const CreateTag = ({
  selected_rid,
  addTag,
  setIsLoading,
  retailTags,
}) => {
  const classes = useStyles()

  const [tagName, setTagName] = useState('')
  const [error, setError] = useState(false)
  const { handleCreateTag } = useTag()

  const handleSubmit = () => {
    if (tagNameValidation(retailTags, tagName)) {
      handleCreateTag({ tagName, selected_rid }, addTag, setIsLoading)
      setError(false)
      setTagName('')
    } else setError(true)
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        style={{
          width: '30%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" color="gray">
          Create Tag
        </Typography>
      </Box>

      <Box style={{ width: '30%' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            border: '1px solid #cbc8c8',
            borderRadius: '20px',
            transition: '0.2s linear',
            '&:hover': {
              ' -webkit-transform': 'scale(1.1)',
              transform: 'scale(1.1)',
            },
          }}
        >
          <InputBase
            value={tagName}
            onChange={e => {
              setTagName(e.target.value)
              setError(false)
            }}
            style={{ width: '90%', padding: '10px' }}
            placeholder="type name of the tag"
            error={true}
          />
          <Button
            disabled={tagName === ''}
            onClick={() => handleSubmit()}
            style={{
              cursor: 'pointer',
              borderTopRightRadius: '19px',
              borderBottomRightRadius: '19px',
              color: 'white',
              height: '52px',
              width: '100px',
              background: tagName === '' ? 'gray' : '#7070b4',
            }}
          >
            Create
          </Button>
        </Box>

        {error && (
          <Typography className={classes.errorText}>
            The name must be uniq
          </Typography>
        )}
      </Box>
    </Box>
  )
}
