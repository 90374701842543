import React from 'react'
import { MAX_TAGS_LENGTH_IN_GROUP } from '../utils'
import { Box, Typography, Divider, Tooltip, Chip } from '@material-ui/core'
import { useStyles } from '../style'
import { StyledCheckbox } from '../StyledCheckBox'
import { useMemo } from 'react'

const tooltipLabel = `Max ${MAX_TAGS_LENGTH_IN_GROUP} tags allowed`
export const GroupListItem = ({
  group,
  handleCheckedGroups,
  checkedIds,
  retailTags,
  selectedTag,
  isChecked,
}) => {
  const { title, id, tags } = group
  const classes = useStyles()

  const groupTags = useMemo(() => {
    if (!selectedTag) return []
    let tagsCopy = tags ? [...tags] : []

    if (isChecked) {
      tagsCopy.push(selectedTag.sk)
    } else if (!isChecked && tagsCopy.includes(selectedTag.sk)) {
      tagsCopy = tagsCopy.filter(tag => tag !== selectedTag.sk)
    }

    return retailTags.filter(tag => tagsCopy.includes(tag.sk))
  }, [isChecked, tags, retailTags, selectedTag])

  const disabled =
    !isChecked && groupTags && groupTags.length >= MAX_TAGS_LENGTH_IN_GROUP

  return (
    <>
      <Tooltip
        style={{ fontSize: '16px' }}
        size="small"
        title={disabled ? tooltipLabel : ''}
        arrow
        disableHoverListener={!disabled || !tooltipLabel}
        classes={{ tooltip: classes.tooltip }}
      >
        <Box className={classes.groupListWrapper}>
          <Box display="flex" flexDirection="column" alignItems={'flex-start'}>
            <Box className={classes.userInfo}>
              <Typography style={{ marginLeft: '20px' }}>{title}</Typography>
            </Box>
            {groupTags && groupTags.length > 0 && (
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                alignItems="center"
                style={{ marginLeft: '15px', gap: '5px' }}
              >
                {groupTags.map((tag, i) => (
                  <Chip
                    label={tag.name}
                    style={{ width: 'max-content', maxWidth: '100px' }}
                    key={i}
                    color="primary"
                  />
                ))}
              </Box>
            )}
          </Box>

          <StyledCheckbox
            id={id}
            handleCheckedGroups={handleCheckedGroups}
            checkedIds={checkedIds}
            disabled={disabled}
          />
        </Box>
      </Tooltip>
      <Divider />
    </>
  )
}
