import Keys from '../../utils/Keys'

const useResponse = ({ handleAlert, logout, callback }) => {
  const handleResponseError = status => {
    if (status === '401') {
      logout()
    }
  }

  const handleSubmissionResponse = response => {
    let msg = 'ERROR: changes were not saved, something is wrong with the data'
    let alertType = Keys.ALERT_ERROR

    if (response !== undefined && response.status === 200) {
      msg = 'Changes Were Saved :) !'
      alertType = Keys.ALERT_SUCCESS
      callback()
    }

    handleAlert({
      text: msg,
      type: alertType,
    })
    handleResponseError(response.status.toString())
  }
  return {
    handleSubmissionResponse,
  }
}

export default useResponse
