import React, { memo } from 'react'
import { Avatar, Box, Typography } from '@material-ui/core'
import { useStyles } from './style'

export const SelectedUsers = memo(({ users, checkedIds }) => {
  const classes = useStyles()
  return (
    <Box marginTop="20px">
      <Typography>Choosed Users</Typography>
      <Box className={classes.selectedGroupsContainer}>
        {checkedIds.map(id => {
          const user = users.find(user => user.uid === id)
          if (!user) return null
          const fullname = `${user.first_name} ${user?.last_name ?? ''}`
          const avatar = user?.profile_img_url ?? ''
          return (
            <Box
              key={id}
              style={{
                background: 'rgb(232, 233, 241)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '20px',
                columnGap: '10px',
                padding: '5px',
                margin: '5px',
              }}
            >
              <Avatar src={avatar} alt="user avatar" size={10} />
              <Typography>{fullname}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
})
