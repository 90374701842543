import React, { useMemo, memo } from 'react'

import { useStyles } from '../style'
import { Box, Typography, Avatar, Chip, Tooltip } from '@material-ui/core'
import { StyledCheckbox } from '../StyledCheckBox'
import { MAX_TAGS_LENGTH_IN_USER } from '../utils'

const tooltipLabel = `Max ${MAX_TAGS_LENGTH_IN_USER} tags allowed`

export const UsersListItem = memo(
  ({
    user,
    handleCheckUsers,
    checkedIds,
    retailTags,
    selectedTag,
    isChecked,
  }) => {
    const classes = useStyles()
    if (!user) {
      return null
    }
    const { first_name, last_name, uid, profile_img_url, tags } = user
    const fullName = `${first_name} ${last_name ?? ''}`

    const usersTags = useMemo(() => {
      if (!selectedTag) return []
      let tagsCopy = tags ? [...tags] : []
      if (isChecked) {
        tagsCopy.push(selectedTag.sk)
      } else if (!isChecked && tagsCopy.includes(selectedTag.sk)) {
        tagsCopy = tagsCopy.filter(tag => tag !== selectedTag.sk)
      }
      return retailTags.filter(tag => tagsCopy.includes(tag.sk))
    }, [isChecked, tags, retailTags, selectedTag])

    const disabled =
      !isChecked && usersTags && usersTags.length >= MAX_TAGS_LENGTH_IN_USER
    return (
      <>
        <Tooltip
          style={{ fontSize: '16px' }}
          size="small"
          title={disabled ? tooltipLabel : ''}
          arrow
          disableHoverListener={!disabled || !tooltipLabel}
          classes={{ tooltip: classes.tooltip }}
        >
          <Box className={classes.groupListWrapper}>
            <Box display="flex" flexDirection={'row'} alignItems={'center'}>
              <Avatar src={profile_img_url ?? ''} alt="user avatar" size={40} />
              <Box
                display={'flex'}
                flexDirection="column"
                alignItems={'flex-start'}
              >
                <Box className={classes.userInfo}>
                  <Typography style={{ marginLeft: '20px' }}>
                    {fullName}
                  </Typography>
                </Box>
                {usersTags && usersTags.length > 0 && (
                  <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    alignItems="center"
                    style={{ marginLeft: '15px', gap: '5px' }}
                  >
                    {usersTags.map((tag, i) => (
                      <Chip
                        label={tag.name}
                        style={{ width: 'max-content', maxWidth: '80px' }}
                        key={i}
                        color="primary"
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Box>

            <StyledCheckbox
              id={uid}
              handleCheckedGroups={handleCheckUsers}
              checkedIds={checkedIds}
              disabled={disabled}
            />
          </Box>
        </Tooltip>
      </>
    )
  }
)
