import { updateTag, createTag, deleteTag } from '../../services/api'

const useTag = () => {
  const handleUpdateTag = (handleSubmissionResponse, body, setIsLoading) => {
    updateTag(handleSubmissionResponse, body, setIsLoading)
  }
  const handleCreateTag = (body, addTag, setIsLoading) => {
    createTag(body, addTag, setIsLoading)
  }
  const handleDeleteTag = (handleSubmissionResponse, body, setIsLoading) => {
    deleteTag(handleSubmissionResponse, body, setIsLoading)
  }
  return {
    handleUpdateTag,
    handleCreateTag,
    handleDeleteTag,
  }
}

export default useTag
