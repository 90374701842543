import React, { memo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useStyles } from './style'

export const SelectedGroups = memo(({ groups, checkedIds }) => {
  const classes = useStyles()
  return (
    <Box marginTop="20px">
      <Typography>Choosed Groups</Typography>
      <Box className={classes.selectedGroupsContainer}>
        {checkedIds.map(id => {
          return (
            <Box key={id} className={classes.selectedGroup}>
              <Typography>{groups[id].name}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
})
