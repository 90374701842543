import React from 'react'
import Layout from '../components/layout'
import TagManager from '../components/Tags'

const Tags = () => {
  return (
    <>
      <Layout>
        <TagManager />
      </Layout>
    </>
  )
}

export default Tags
