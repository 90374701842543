import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(
  () => ({
    rootTags: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      width: '80%',
    },
    tooltip: {
      fontSize: '16px',
    },
    searchTagWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '30%',
      border: '1px solid #cbc8c8',
      borderRadius: '20px',
      transition: '0.2s linear',
      '&:hover': {
        ' -webkit-transform': 'scale(1.1)',
        transform: 'scale(1.1)',
      },
    },
    editTagWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid #cbc8c8',
      borderRadius: '20px',
    },

    tagInput: { width: '90%', padding: '10px' },
    dividerVertical: {
      height: 28,
      margin: 4,
    },
    dividerHorizontal: {
      width: '100%',
      marginTop: '30px',
      marginBottom: '30px',
    },
    tagContainer: {
      cursor: 'pointer',
      padding: '10px',
      borderRadius: '19px',
      height: '60px',
      display: 'flex',
      minWidth: '170px',
      width: 'max-content',
      margin: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s linear',
      '&:hover': {
        ' -webkit-transform': 'scale(1.1)',
        transform: 'scale(1.1)',
      },
    },
    tagsContainer: {
      width: '100%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: '30px',
    },
    tagModal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      background: 'white',
      borderRadius: '40px',
      paddingTop: '16px',
      paddingRight: '32px',
      paddingBottom: '24px',
      minWidth: '700px',
      paddingLeft: '32px',
      width: '60%',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '90vh',
    },
    groupListWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    btn: {
      borderRadius: '20px',
      width: '100%',
      color: 'white',
      margin: '10px',
      transition: '0.3s linear',
      '&:hover': {
        ' -webkit-transform': 'scale(1.1)',
        transform: 'scale(1.1)',
      },
    },
    delete: {
      background: '#e44c4c',
      '&:hover': {
        background: '#e44c4c',
      },
    },
    listTagsBlock: {
      display: 'flex',
      alignText: 'left',
      flexDirection: 'column',
      width: '100%',
    },
    groupsTreeContainer: {
      dispaly: 'flex',
      marginTop: '10px',
      flexDirection: 'column',
      height: '41vh',
      overflowY: 'scroll',
      textAlign: 'center',
      color: 'gray',
    },
    selectedGroupsContainer: {
      border: '1px solid #cbc8c8',
      borderRadius: '15px',
      width: '100%',
      maxHeight: '27vh',

      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      overflowY: 'scroll',
    },
    selectedGroup: {
      display: 'flex',
      alignItems: 'center',
      margin: '5px',
      padding: '15px',
      width: 'max-content',
      height: '40px',
      background: 'rgb(232, 233, 241)',
      borderRadius: '20px',
    },
    addTagBtn: {
      cursor: 'pointer',
      borderTopRightRadius: '19px',
      borderBottomRightRadius: '19px',
      color: 'white',
      height: '52px',
      width: '100px',
    },
    errorText: {
      color: 'red',
      marginTop: '10px',
    },
  }),
  { index: 1 }
)
