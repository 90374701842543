import React, { useState } from 'react'
import { useStyles } from './style'
import {
  Box,
  InputBase,
  Button,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import LinearProgress from '@material-ui/core/LinearProgress'

export const EditTagName = ({
  tagName,
  setTagName,
  retailTags,
  setIsEdit,
  isEdit,
  selectedTag,
  loading,
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)

  const tagNameValidation = () => {
    const findedTag = retailTags?.find(
      tag => tag.name === tagName && tagName !== selectedTag.name
    )
    if (findedTag !== undefined) return false
    return true
  }
  const handleSubmit = () => {
    if (tagNameValidation()) {
      setError(false)
      setIsEdit(prev => !prev)
    } else setError(true)
  }

  return (
    <Box style={{ width: '100%' }}>
      {!isEdit ? (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <Box>
              <Typography variant="h5" className={classes.text}>
                Tag Name:
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5">{`  ${tagName}`}</Typography>
            </Box>
            <IconButton onClick={() => setIsEdit(prev => !prev)}>
              <EditIcon />
            </IconButton>
          </Box>
          {loading ? (
            <Box style={{ marginTop: '10px', width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <Divider style={{ marginTop: '10px' }} />
          )}
        </>
      ) : (
        <>
          <Box className={classes.editTagWrapper}>
            <InputBase
              value={tagName}
              onChange={e => {
                setTagName(e.target.value)
                setError(false)
              }}
              className={classes.tagInput}
              placeholder="type name of the tag"
            />
            <Button
              onClick={() => handleSubmit()}
              style={{
                background: '#7070b4',
                borderTopRightRadius: '19px',
                borderBottomRightRadius: '19px',
                color: 'white',
                height: '52px',
                width: '100px',
              }}
            >
              Change Name
            </Button>
          </Box>
          {error && (
            <Typography style={{ color: 'red', marginTop: '10px' }}>
              The name must be uniq
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}
