export const MAX_TAGS_LENGTH_IN_USER = 3
export const MAX_TAGS_LENGTH_IN_GROUP = 5
export const handleIds = (checkedIds, id) => {
  if (checkedIds.includes(id)) {
    return checkedIds.filter(el => el !== id)
  } else {
    return [...checkedIds, id]
  }
}

export const handleSelectAll = (items, setCheckedIds) => {
  const itemsIds = items.map(item => item?.id ?? item?.uid)

  setCheckedIds(prevState => {
    return prevState.length === items.length ? [] : itemsIds
  })
}
export const handleSelectAllSearchResults = (setCheckedIds, searchResults) => {
  const searchResultsIds = searchResults.map(({ id }) => id)

  setCheckedIds(prevState => {
    if (searchResultsIds.every(id => prevState.includes(id))) {
      return prevState.filter(id => !searchResultsIds.includes(id))
    } else {
      return [
        ...prevState,
        ...searchResultsIds.filter(id => !prevState.includes(id)),
      ]
    }
  })
}

export const tagNameValidation = (retailTags, tagName) => {
  const findedTag = retailTags?.find(tag => tag.name === tagName)
  if (findedTag !== undefined) return false
  return true
}

export const updateGroupTags = (groupIds, action, groups, selectedTag) => {
  const updatedGroups = { ...groups }
  groupIds.forEach(oid => {
    if (updatedGroups[oid]) {
      const currentTags = new Set(updatedGroups[oid].tags)
      if (action === 'add') {
        currentTags.add(selectedTag.sk)
      } else if (action === 'delete') {
        currentTags.delete(selectedTag.sk)
      }
      updatedGroups[oid].tags = Array.from(currentTags)
    }
  })
  return updatedGroups
}

export const updateUserTags = (userIds, action, users, selectedTag) => {
  return users.map(user => {
    if (userIds.includes(user.uid)) {
      const updatedTags =
        action === 'add'
          ? [...(user.tags ?? []), selectedTag.sk]
          : user.tags.filter(tag => tag !== selectedTag.sk)
      return { ...user, tags: updatedTags }
    }
    return user
  })
}

export const getItemSize = (index, itemsToShow) => {
  const item = itemsToShow[index]
  const baseHeight = 70
  const tagHeight = 34
  const tagsPerBlock = 2

  const tagsCount = item.tags ? item.tags.length : 0
  const tagBlocks = Math.ceil(tagsCount / tagsPerBlock)

  return baseHeight + tagBlocks * tagHeight
}
