import React from 'react'
import { Checkbox } from '@material-ui/core'

export const StyledCheckbox = ({
  id,
  handleCheckedGroups,
  checkedIds,
  disabled = false,
}) => {
  return (
    <Checkbox
      onChange={() => {
        handleCheckedGroups(id)
      }}
      checked={checkedIds?.includes(id)}
      disableRipple
      cursor={disabled ? 'not-allowed' : 'pointer'}
      disabled={!checkedIds?.includes(id) && disabled}
      color="default"
      inputProps={{ 'aria-label': 'decorative checkbox' }}
    />
  )
}
