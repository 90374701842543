import React, { useState, memo, useMemo } from 'react'
import { Box, Typography, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useStyles } from './style'
import EditTagModal from './EditTagModal'
import useToggle from './useToggle'

const TagItem = memo(({ tag, handleOpen, setSelectedTag }) => {
  const { name, groups, users } = tag
  const classes = useStyles()

  const onOpen = () => {
    handleOpen()
    setSelectedTag(tag)
  }
  return (
    <>
      <Box
        onClick={() => onOpen()}
        className={classes.tagContainer}
        style={{
          background:
            (groups && groups.length !== 0) || (users && users.length !== 0)
              ? 'rgb(121, 159, 216)'
              : '#424858',
        }}
      >
        <Typography style={{ color: 'white', fontWeight: '700' }}>
          {name}
        </Typography>
      </Box>
    </>
  )
})

export const ListTags = ({ setSelectedTag, retailTags, loading }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const { handleOpen, open, handleClose } = useToggle()

  const searchResult = useMemo(
    () =>
      retailTags.filter(tag =>
        tag.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
      ),
    [searchValue, retailTags]
  )
  const tagsToShow = useMemo(() => (searchResult ? searchResult : retailTags), [
    searchResult,
    retailTags,
  ])

  return (
    <Box className={classes.listTagsBlock}>
      {tagsToShow.length !== 0 ? (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box className={classes.addTagTextBlock}>
              <Typography variant="h4" className={classes.text}>
                List of Tags
              </Typography>
            </Box>
            <Box className={classes.searchTagWrapper}>
              <InputBase
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className={classes.tagInput}
                placeholder="search tag"
              />
              <SearchIcon fill="gray" />
            </Box>
          </Box>
          <Box className={classes.tagsContainer}>
            {tagsToShow.map((tag, i) => {
              return (
                <TagItem
                  key={i}
                  tag={tag}
                  setSelectedTag={setSelectedTag}
                  handleOpen={handleOpen}
                />
              )
            })}
            <EditTagModal handleClose={handleClose} open={open} />
          </Box>
        </>
      ) : (
        <Typography variant="h3" className={classes.text}>
          {!loading && 'There are no tags here yet... Please create a tag'}
        </Typography>
      )}
    </Box>
  )
}
