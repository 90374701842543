import React from 'react'
import { Box, Divider } from '@material-ui/core'
import { CreateTag } from './CreateTag'
import { useStyles } from './style'
import { ListTags } from './ListTags'
import withGroups from '../Elements/withGroups'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import LinearProgress from '@material-ui/core/LinearProgress'
import withRetailUsers from '../Elements/withRetailUsers'

export const TagManager = ({
  selected_rid,
  retailTags,
  setSelectedTag,
  addTag,
  setIsLoading,
  loading,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.rootTags}>
      <CreateTag
        selected_rid={selected_rid}
        addTag={addTag}
        retailTags={retailTags}
        setIsLoading={setIsLoading}
      />
      {loading ? (
        <Box className={classes.dividerHorizontal}>
          <LinearProgress />
        </Box>
      ) : (
        <Divider className={classes.dividerHorizontal} />
      )}

      <ListTags
        retailTags={retailTags}
        setSelectedTag={setSelectedTag}
        loading={loading}
      />
    </Box>
  )
}
const mapStateToProps = ({
  groups,
  retailTags,
  setSelectedTag,
  selected_rid,
  addTag,
  setIsLoading,
  loading,
}) => ({
  groups,
  retailTags,
  setSelectedTag,
  selected_rid,
  addTag,
  setIsLoading,
  loading,
})

export default connect(
  mapStateToProps,
  actions
)(withGroups(withRetailUsers(TagManager)))
