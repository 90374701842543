import React, { useMemo, useRef, useState } from 'react'
import { VariableSizeList as List } from 'react-window'
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver'
import { handleIds } from '../utils'
import { Box, Typography, Divider } from '@material-ui/core'
import { useStyles } from '../style'
import { SelectAllBlock } from '../SelectAllBlock'
import { connect } from 'redux-zero/react'
import { GroupListItem } from './GroupListItem'
import { getItemSize } from '../utils'

const Row = ({ index, style, data }) => {
  const {
    groups,
    checkedIds,
    handleCheckedGroups,
    retailTags,
    selectedTag,
  } = data
  return (
    <div style={style}>
      <GroupListItem
        key={groups[index].id}
        group={groups[index]}
        isChecked={checkedIds.includes(groups[index].id)}
        handleCheckedGroups={handleCheckedGroups}
        checkedIds={checkedIds}
        retailTags={retailTags}
        selectedTag={selectedTag}
      />
      <Divider />
    </div>
  )
}
const GroupList = ({
  groupsObjectToArray,
  searchResults,
  setCheckedIds,
  checkedIds,
  searchValue,
  selectedTag,
  retailTags,
}) => {
  const containerRef = useRef(null)
  const [height, setHeight] = useState(600)
  const classes = useStyles()

  useResizeObserver(containerRef, entries => {
    if (entries && entries[0]) {
      setHeight(entries[0].contentRect.height)
    }
  })
  const handleCheckedGroups = id => {
    setCheckedIds(handleIds(checkedIds, id))
  }
  const groupsToShow = useMemo(
    () => (searchResults.length !== 0 ? searchResults : groupsObjectToArray),
    [searchResults, groupsObjectToArray]
  )

  const itemData = {
    groups: groupsToShow,
    checkedIds,
    handleCheckedGroups,
    retailTags,
    selectedTag,
    getItemSize: index => getItemSize(index, groupsToShow),
  }

  return (
    <>
      {searchResults.length === 0 && searchValue !== '' ? (
        <Box textAlign="center" color="gray" marginTop="20px">
          <Typography variant="h4"> Not Found...</Typography>
        </Box>
      ) : (
        <>
          <SelectAllBlock
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
            searchResults={searchResults}
            dataArray={groupsObjectToArray}
          />
          <Box ref={containerRef} className={classes.groupsTreeContainer}>
            <List
              height={height}
              itemCount={groupsToShow.length}
              itemSize={index => getItemSize(index, groupsToShow)}
              width="100%"
              itemData={itemData}
            >
              {Row}
            </List>
          </Box>
        </>
      )}
    </>
  )
}

const mapStateToProps = ({ retailTags, selectedTag }) => ({
  retailTags,
  selectedTag,
})

export default connect(mapStateToProps)(GroupList)
